.teaser-bundle.inverted {
  --border-color: var(--c-tint-30);

  background: var(--c-dark);

  @include respond-to(tablet) {
    padding: var(--space-4);
    border-radius: var(--space-1);
  }

  .bundle-title {
    background: var(--c-dark);
    border-bottom: 1px solid var(--border-color);

    .title {
      color: var(--c-light);
    }
  }
  .teaser-section-title {
    color: var(--c-light);
  }

  .teaser.inverted {
    .description {
      color: var(--c-neutral-03);
    }
    .author-name,
    .kicker {
      color: var(--c-light);
    }
    .author-title {
      color: var(--c-neutral-05);
    }

    &.with-image {
      .container,
      .text-wrapper-small {
        padding: 0;
      }
    }
  }

  .secondary-teasers-wrapper {
    .teaser.bundle-secondary {
      .title {
        color: var(--c-light);
      }
    }
  }
}
