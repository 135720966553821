.byline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  white-space: pre-line;
  margin-top: auto;
  gap: var(--space-4);
  &__image-container {
    display: flex;
    .author-image-wrapper:nth-child(2) {
      margin-left: var(--space-1);
    }
  }
}

.byline--with-count {
  flex-direction: column;
  align-items: flex-start;
}

.teaser .author-name {
  @include label-2(var(--c-primary-04), 600);
  margin-right: var(--space-2);
}

.teaser .author-title {
  @include label-3(var(--c-neutral-06), 400, italic);
  @include line-clamp(2);
}

.author-image-wrapper {
  position: relative;
  margin-left: var(--space-2);

  img {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }

  &--no-grayscale {
    background: transparent;
    position: relative;
    .author-image {
      filter: none;
    }
  }
}

.author-image {
  border-radius: 50%;
  @include author-pic(56px, 12px, 100%);
  margin: 0;
  border: none;
  display: block;
  filter: grayscale(100%);
}

.teaser.illustration .author-image {
  width: 118px;
  height: 118px;
  border: none;
  border-radius: 0;
}

.teaser.illustration.small .author-image {
  width: 94px;
  height: 94px;
}

.teaser.inverted {
  .author-name {
    color: var(--c-neutral-05);
  }
}
.teaser.small.opinion.with-byline-image:not(.bundle-secondary) {
  .author-image-wrapper {
    display: none;
  }
}
// desktop

@media (min-width: 765px) {
  .teaser.small.opinion.with-byline-image {
    .author-image-wrapper {
      display: block;
    }
  }
  .teaser.opinion .byline {
    border-bottom: none;
  }

  .teaser {
    .author-image-wrapper {
      flex-shrink: 0;
      &--no-grayscale {
        flex-shrink: 0;
      }
    }
    .author-name {
      margin-right: 0;
    }
    &.illustration .author-image {
      height: 76px;
      width: 76px;
    }
    &.large {
      &.illustration .author-title {
        text-align: start;
      }
      &.illustration .author-image {
        height: 127px;
        width: 127px;
      }
    }
    &.medium {
      .byline,
      .illustration-container {
        margin-bottom: 0;
      }
      .byline,
      .author {
        margin-top: var(--space-2);
      }
      &.illustration .author-image {
        height: 88px;
        width: 88px;
      }
    }
  }
  .double {
    .teaser {
      &.opinion {
        .byline {
          margin-top: auto;
        }
      }
    }
  }

  // can be deleted?
  .teaser.small.horizontal {
    .byline {
      flex-direction: column-reverse;
      justify-content: center;
      margin-bottom: 0;
      padding-bottom: 0;
      text-align: end;
      width: 123px;

      &.byline--with-count {
        width: auto;
      }

      &.opinion.with-image {
        text-align: start;
      }

      .author-image-wrapper::after {
        right: 0;
      }
    }

    &.opinion.with-image .byline {
      position: relative;
      top: calc(-1 * var(--space-4));
      text-align: start;
    }

    &.inverted .byline {
      padding: var(--space-4) var(--space-4) var(--space-4) 0;
    }
  }
}
