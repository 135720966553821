@import '../../../styles-common/variables';
@import '../../../../../core/scss/helpers/mixins';

.teaser-bundle {
  @include teaser-series-top('.teaser:not(.span-1)', '.context-label-series');
  --border-color: var(--c-shade-10);
  --border-spacing: var(--space-4);

  margin-bottom: var(--space-2);

  background: var(--c-light);

  @include respond-to(tablet) {
    --border-spacing: var(--space-6);
    margin-bottom: 0;
  }

  .bundle-teasers-wrapper {
    padding: var(--space-4);

    .teaser-link .container .kicker {
      color: var(--c-neutral-09);
      font-size: 14px;
      margin-bottom: 6px;
    }

    @include respond-to(tablet) {
      padding: var(--space-6) 0 0 0;

      .teaser-link .container .kicker {
        font-size: 15px;
      }
    }

    /**
    * Section/tag label etc shouldn't show in bundle teasers,
    * with except of "breaking" label on main breaking teaser.
    */
    .teaser:not(.large.breaking) {
      .container .information {
        display: none;
      }
    }

    /* Main teaser */
    .teaser.large {
      padding: 0 0 var(--border-spacing) 0;
      margin-bottom: var(--border-spacing);
      border-bottom: 1px solid var(--border-color);

      .description {
        @include description(14px, 20px, var(--c-neutral-07));
        margin-top: var(--space-2);

        @include respond-to(tablet) {
          margin-top: var(--space-4);
        }
      }

      .teaser-link {
        display: flex;

        /**
        * Flip order of image and text for bundle teasers (image on bottom, content/text on top).
        * In standalone teasers, images are on top.
        */
        flex-direction: column-reverse;
        gap: var(--space-8);

        @include respond-to(tablet) {
          .container {
            margin: 0;
          }
        }

        .teaser-image-wrapper {
          margin-bottom: 0;
        }
      }
    }

    .secondary-teasers-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--space-4);

      @include respond-to(tablet) {
        grid-gap: var(--space-6) var(--space-12);
      }

      .teaser.bundle-secondary {
        padding: 0;
        margin: 0;
        grid-column: span 2;
        max-width: 100%;

        .container {
          display: flex;
          justify-content: space-between;
          border: none;
          padding: 0;
        }

        &.span-1 {
          grid-column: span 1;
          grid-row: span 1;
          border: none;

          .container {
            flex-direction: column-reverse;

            .teaser-image-wrapper {
              aspect-ratio: 1 / 1;

              .teaser-image {
                max-width: 100%;
                width: 100%;
                height: 100%;
                // height: 171px;
              }

              @include respond-to(tablet) {
                aspect-ratio: 3 / 2;
                width: 100%;

                .teaser-image {
                  // height: auto;
                  min-width: 100%;
                  min-height: 100%;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          @include respond-to(tablet) {
            /* Paired opinion on desktop */
            &.opinion {
              .teaser-link .container {
                flex-direction: column;

                .byline {
                  display: flex;
                  justify-content: space-between;

                  .author-image {
                    width: 56px;
                    height: 56px;
                  }
                }
              }

              &.illustration {
                .container {
                  gap: var(--space-4);

                  .illustration-container {
                    display: grid;
                    grid-template-areas:
                      'title title ils'
                      'title title ils'
                      'byline byline ils';

                    .text {
                      grid-area: title;
                    }
                    .illustration-byline-info {
                      grid-area: byline;
                    }
                    .author-image-wrapper--no-grayscale {
                      grid-area: ils;
                    }
                  }
                }
              }
            }
          }
        }

        /* Divider logic */
        &:not(.span-1) {
          &:not(:first-child) {
            padding-top: var(--border-spacing);
            border-top: 1px solid var(--border-color);
          }

          &:has(~ .span-1) {
            /**
              * add border bottom
              * to horizontal teaser if a paired row is below,
              * since paired teasers don't have border top
            */
            padding-bottom: var(--border-spacing);
            border-bottom: 1px solid var(--border-color);
          }
        }

        @include respond-to(tablet) {
          /* Border between paired */
          &.span-1:has(~ .span-1) {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              width: 1px;
              height: 100%;
              top: 0;
              right: -24.5px;
              background-color: var(--border-color);
            }
          }
        }
      }
    }
  }
}

@import './bundle-header';
@import './variants';
@import './inverted';
