/* @import '../../../styles-common/variables';
@import '../../../../../core/scss/helpers/mixins'; */

.teaser-bundle {
  &.breaking {
    .teaser.breaking .kicker {
      color: #b0002f;
    }
    .large.breaking {
      .information,
      .kicker {
        margin-bottom: 8px;
      }
    }

    .bundle-secondary.breaking {
      &.span-1 .title {
        @include title-breaking(17px, 20px);
      }

      @include respond-to(tablet) {
        &,
        &.span-1 .title {
          @include title-breaking(24px, 26px);
        }
      }
    }
  }

  &.opinion {
    .large {
      .teaser-link {
        gap: var(--space-4);
      }
    }
  }
  .secondary-teasers-wrapper {
    .teaser:not(.span-1) .author-image {
      width: 94px;
      height: 94px;
    }
  }
}
