.teaser-bundle {
  .bundle-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--space-4) 0;
    margin: 0 var(--space-4);
    background: var(--c-light);
    position: relative;
    border-bottom: 1px solid var(--c-shade-50);

    @include respond-to(tablet) {
      border-color: var(--c-shade-10);
      margin: 0;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.5px;
      padding: 0 0 var(--space-4) 0;
    }

    .title {
      @include title-list();
      display: flex;
    }
  }

  &.magazine .bundle-title {
    justify-content: center;
  }
}
